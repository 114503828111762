<template>
    <!-- <div class="action-manner-feedback">
        <div class="title f-bold" v-html="title" />
        <div class="body" v-html="body" />
    </div> -->
    <div class="action-manner-feedback">
        <div class="title f-bold" v-html="title" />
        <!-- <div class="body" v-html="body" /> -->
        <FeedbackItem v-if="message.mtype === 'manner-complete'" :feedbackItem="getFeedbackItem()" />
        <!-- <FeedbackItem v-if="message.mtype === 'manner-complete'" :feedbackItem="{user: getUser(content), meet : getMeet(content) , feedback: getFeedback(content)}"/> -->
        <div v-else-if="message.mtype === 'manner-request'">
            <!-- content 안의list 를 v-for -->
            <MeetingInfo
                v-for="(target, idx) in content.targets"
                :key="idx"
                :user="getUser(target)"
                :meet="getMeet(target)"
                class="meeting-info"
            />
            <!-- <MeetingInfo :user="user" :meet="meet" class="meeting-info" /> -->
        </div>
        <div v-else class="body" v-html="body" />
    </div>
</template>

<script>
import FeedbackItem from '@/routes/chat/meeting-feedback/FeedbackItem'
import MeetingInfo from '@/routes/chat/meeting-feedback/MeetingInfo'

export default {
    name: 'ActionScheduleConfirm',
    props: ['message'],
    components: {
        FeedbackItem,
        MeetingInfo,
    },
    data: () => ({}),
    computed: {
        title() {
            if (this.message.mtype === 'manner-complete') {
                // return '매너피드백 완료'
                return '피드백이 완료되었어요'
            } else if (this.message.mtype === 'manner-request') {
                return '피드백 요청하세요!'
            } else {
                return '매너피드백 전송'
            }
        },
        content() {
            return this.$mustParse(this.message.content)
        },
        body() {
            if (this.message.mtype === 'manner-complete') {
                return `${this.content.name} / ${this.$options.filters.asAge(this.content.birthday)} / ${
                    this.content.job
                } 님과의<br> 만남 피드백이 완료되었습니다.`
            } else {
                return `${this.content.name} / ${this.$options.filters.asAge(this.content.birthday)} / ${
                    this.content.job
                } 님과의<br> 만남 피드백을 요청했습니다.`
            }
        },

        getUser() {
            return person => {
                return {
                    photo: person.urls[0],
                    name: person.name,
                    birthday: person.birthday,
                    job: person.job,
                }
            }
        },
        getMeet() {
            return person => {
                return {
                    date: person.schedule.date_confirmed,
                    place: person.schedule.meet_place,
                }
            }
        },
        getFeedback() {
            return person => {
                const steps = Object.fromEntries(Object.entries(person).filter(([key]) => key.includes('step')))

                const feedbackQuestion = steps.step_6.length
                    ? [
                          {
                              id: 1,
                              q: '만남 어땠는지',
                          },
                          {
                              id: 10,
                              q: '만나지 않은 이유',
                          },
                          {
                              id: 2,
                              q: '연락할 의향',
                          },
                          {
                              id: 3,
                              q: '가벼운 목적인지',
                          },
                          {
                              id: 4,
                              q: '매너 있었는지',
                          },
                          {
                              id: 5,
                              q: '실물 어떤지',
                          },
                          {
                              id: 6,
                              q: '키워드',
                          },
                          {
                              id: 7,
                              q: '추가 작성',
                          },
                      ]
                    : [
                          {
                              id: 1,
                              q: '만남 어땠는지',
                          },
                          {
                              id: 10,
                              q: '만나지 않는 이유',
                          },
                          {
                              id: 2,
                              q: '연락할 의향',
                          },
                          {
                              id: 3,
                              q: '좋았던 점',
                          },
                          {
                              id: 4,
                              q: '안 좋았던 점',
                          },
                          {
                              id: 5,
                              q: '추가 작성',
                          },
                      ]
                const feedbackItem = feedbackQuestion.map(f => {
                    return { ...f, a: steps[`step_${f.id}`] }
                })
                return feedbackItem
            }
        },
        getFeedbackItem() {
            return () => {
                return {
                    user: this.getUser(this.content),
                    meet: this.getMeet(this.content),
                    feedback: this.getFeedback(this.content),
                }
            }
        },
    },
    methods: {},
}
</script>

<style scoped lang="scss">
.action-manner-feedback {
    width: 100%;
    padding: 0 16px;
    .title {
        padding-bottom: 0;
    }
    .meeting-info {
        padding: 12px 0;
        margin: 12px 0;
    }
}
</style>
